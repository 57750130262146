export interface ENSolutionOptions {
  apiUrl?: string;
  intervalTimeout?: number;
  serviceId?: number | null;
}

export interface VisitResponse {
  id: number;
  isIpBlock: boolean;
}

export interface Conv {
  convTypeId: number;
}

class ENSolution {
  private id: number | null = null;

  private isInit: boolean = false;

  private initializing: Promise<void> | null = null;

  private isBlocked: boolean = false;

  private options: ENSolutionOptions = {
    apiUrl: "https://api.ensolution.co.kr",
    intervalTimeout: 15000,
    serviceId: null,
  };

  constructor(options: ENSolutionOptions) {
    if (options) {
      Object.assign(this.options, options);
    }
    this.initializing = this.init();
  }

  private handleCheckServiceId() {
    if (this.options.serviceId === null) {
      throw new Error("[ENSolution] Service id is missing");
    }
  }

  private handleCheckId() {
    if (this.id === null) {
      throw new Error("[ENSolution] Missing id");
    }
  }

  private handleCheckInit() {
    if (this.isInit === null) {
      throw new Error("[ENSolution] No Initializing ENSolution");
    }
  }

  private async handleVisit(): Promise<VisitResponse> {
    this.handleCheckServiceId();

    const response = await window.fetch(
      `${this.options.apiUrl}/api/services/${this.options.serviceId}/visits`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: window.location.href,
          referrer: document.referrer,
        }),
      },
    );

    const data: VisitResponse = await response.json();

    this.id = data.id;

    if (data.isIpBlock) {
      this.handleIpBlock();
    }

    return data;
  }

  private handleIpBlock() {
    if (this.isBlocked) return;

    this.isBlocked = true;

    document.writeln(
      `
<img src="https://dev.cdn.ensolution.co.kr/assets/images/ip_block.jpg" alt="ip block" />
`.trim(),
    );
  }

  private async init(): Promise<void> {
    await this.handleVisit();
    this.isInit = true;
    this.initializing = null;

    (async () => {
      while (true) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, this.options.intervalTimeout);
        });
        await this.handleVisit();
      }
    })().then();
  }

  async conv(conv: Conv) {
    if (this.initializing) await this.initializing;
    this.handleCheckServiceId();
    this.handleCheckInit();
    this.handleCheckId();

    await window.fetch(
      `${this.options.apiUrl}/api/services/${this.options.serviceId}/visits/${this.id}/conv`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          convTypeId: conv.convTypeId,
        }),
      },
    );
  }
}

// @ts-ignore
window.ENSolution = ENSolution;
